@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Roboto', 'Segoe UI','Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */

.button-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.rounded-button {
  font-size: 1.2em;
  background-color: #f59e0c;
  color: #000;
  border: none;
  border-radius: 15px;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rounded-button:hover {
  background-color: #f3b721;
}

@media screen and (max-width: 768px) {
  .button-row {
    flex-wrap: wrap;
  }
  .rounded-button {
   /* width: 100%;
     margin-bottom: 10px; */
     font-size: 1em;
     padding: 15px 30px;
  }
}

.thin-line {
  width: 100%;
  border: 0.5px solid #ccc;
  margin-bottom: 20px;
}

.divCallToDonate {
  background-color: #f2f2f2; /* Change to your preferred background color */
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

/* Text Styling */
.divCallToDonate p {
  font-size: 20px;
  font-weight: bold;
  color: #0072c6; /* Change to your preferred text color */
  margin: 0;
}

.divCallToDonate span {
  font-size: 16px;
  color: #666; /* Change to your preferred text color */
  display: block;
  margin-top: 10px;
  text-align: left;
}

.zeffyContainerDiv {
  position:relative;
  overflow:hidden;
  width:100%;
  height:1200px;
  padding-top:100%
}

.zeffyContainerDiv iframe {
  position: absolute; 
  border: 0; 
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%
}