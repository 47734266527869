.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  width: '100%';
  display: flex;
}

.swiper-slide {
  width: '100%';
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

/* .swiper-slide.swiper-slide-active {
  transform: scale(1.1);
  z-index: 1;
} */

/* ARROWS */
.swiper-button-prev,
.swiper-button-next {
  @apply text-yellow-500;
  /* color: rgba(245 158 11 1); */
}

/* PAGINATION */

.swiper-pagination-bullet {
  @apply bg-coolGray-400 h-3 w-3;
  /* background: rgb(238 240 243 1);
  height: 0.75rem;
  width: 0.75rem; */
}

.swiper-pagination-bullet-active {
  /* background: rgb(245 158 11 1); */
  @apply bg-yellow-500;
}
